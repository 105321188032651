var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"v-select-facebook-page",attrs:{"max-width":+_vm.maxWidth,"min-width":+_vm.minWidth,"options":_vm.options,"value":_vm.pages && _vm.pages.length === 0 ? _vm.emptyPageOption : _vm.value,"disabled":_vm.loading || (_vm.pages && _vm.pages.length === 0) || _vm.disabled},on:{"input":_vm.choose},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_c('div',{staticClass:"v-select-facebook-page__selected"},[_c('img',{attrs:{"src":isFinite(selected.fb_id)
                ? ("https://graph.facebook.com/" + (selected.fb_id) + "/picture")
                : require('@/assets/boosting/placeholder-page.svg'),"onerror":("(this.src !== '" + _vm.defaultPageImage + "') && (this.src = '" + _vm.defaultPageImage + "')"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(selected.label)+" ")])])]}},{key:"option",fn:function(ref){
                var option = ref.option;
return [_c('div',{staticClass:"v-select-facebook-page__option"},[_c('img',{attrs:{"src":isFinite(option.fb_id)
                ? ("https://graph.facebook.com/" + (option.fb_id) + "/picture")
                : require('@/assets/boosting/placeholder-page.svg'),"onerror":("(this.src !== '" + _vm.defaultPageImage + "') && (this.src = '" + _vm.defaultPageImage + "')"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(option.label)+" ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }