









































import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import defaultPageImage from '@/assets/boosting/convert-bomb-page.svg'
import Vue, { PropType } from 'vue'

export type FacebookPage = {
  fb_id: string,
  id: string,
  is_enabled: boolean,
  is_published: boolean,
  name: string,
  type: 'pages'
}

type FacebookPageOption = {
  label: string,
  value: string,
  page: FacebookPage
}

function defaultPage (): FacebookPage {
  return {
    fb_id: '',
    id: 'NaN',
    is_enabled: false,
    is_published: false,
    name: 'Loading...',
    type: 'pages'
  }
}

const emptyPage: FacebookPage = {
  fb_id: '',
  id: 'NaN',
  is_enabled: false,
  is_published: false,
  name: 'Ad Account has no pages',
  type: 'pages'
}

function toOption (_: FacebookPage): FacebookPageOption {
  return {
    label: `${_.name}${_.fb_id ? ` (${_.fb_id})` : ''}`,
    value: _.id,
    page: _
  }
}

export default Vue.extend({
  name: 'VSelectFacebookPage',
  components: { VSelect },
  props: {
    maxWidth: { type: [Number, String], default: 300 },
    minWidth: { type: [Number, String], default: 300 },
    loading: { type: Boolean, default: false },
    pages: { type: Array as PropType<Array<FacebookPage>>, default () { return null } },
    page: { type: Object as PropType<FacebookPage>, default: defaultPage },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({ defaultPageImage, emptyPageOption: toOption(emptyPage) }),
  computed: {
    options (): Array<FacebookPageOption> {
      return this.pages?.map(toOption) || []
    },
    value (): FacebookPageOption {
      return toOption(this.page)
    }
  },
  methods: {
    choose ({ value }: FacebookPageOption) {
      this.$emit('choose-page', this.pages?.find(_ => value === _.id))
    }
  }
})
