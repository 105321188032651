





















































































































import AutoPostBoostingErrors from '@/components/AutoPostBoosting/AutoPostBoostingErrors.vue'
import VSelectFacebookPage, { FacebookPage } from '@/components/AutoPostBoosting/VSelectFacebookPage.vue'
import IntegrationService from '@/services/api/IntegrationService'
import PostBoostingService from '@/services/api/PostBoostingService'
import type { AutoPostBoostingRuleData } from '@/views/AutoPostBoosting/AutoPostBoostingEdit.vue'
import type { TJsonApiBody } from 'jsona/lib/JsonaTypes'
import type { AxiosResponse } from 'axios'
import AdAccountDropdown from '@/components/AdAccountDropdown.vue'
import BaseInputNumberWithButtons
  from '@/components/BaseComponents/BaseInputNumberWithButtons/BaseInputNumberWithButtons.vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import props, { IProps } from '@/views/AutoPostBoosting/steps/props'
import Jsona from 'jsona'
import Vue from 'vue'

const jsona = new Jsona()

const actionNotAvailable = {
  content: 'Action not available',
  classes: ['auto-post-boosting-details__tooltip']
} as const

type Data = {
  adAccounts: unknown | null
  facebookPages: unknown | null
  actionNotAvailable: typeof actionNotAvailable
}

export default Vue.extend({
  name: 'AutoPostBoostingDetails',
  mixins: [props as IProps<AutoPostBoostingRuleData>],
  components: { AutoPostBoostingErrors, VSelectFacebookPage, BaseInputNumberWithButtons, AdAccountDropdown, BaseInputVuelidate },
  data (): Data {
    return {
      adAccounts: null,
      facebookPages: null,
      actionNotAvailable
    }
  },
  computed: {
    name: {
      get (): string {
        return this.data ? this.data.name : 'Loading...'
      },
      set (value: string) {
        if (this.errors.name) {
          this.$emit('remove-error', 'name')
        }
        if (this.data) {
          this.data.name = value
        }
      }
    },
    boosted_posts_count: {
      get (): number {
        return this.data?.boosted_posts_count ?? NaN
      },
      set (value: number) {
        if (this.errors.boosted_posts_count) {
          this.$emit('remove-error', 'boosted_posts_count')
        }
        if (this.data) {
          this.data.boosted_posts_count = value
        }
      }
    },
    page_id: {
      get (): number {
        return this.data?.page_id ?? NaN
      },
      set (value: number) {
        if (this.errors.page_id) {
          this.$emit('remove-error', 'page_id')
        }
        if (this.data) {
          this.data.page_id = value
        }
      }
    },
    ad_account_id: {
      get (): number {
        return this.data?.ad_account_id ?? NaN
      },
      set (value: number) {
        if (this.errors.ad_account_id) {
          this.$emit('remove-error', 'ad_account_id')
        }
        if (this.data) {
          this.data.ad_account_id = value
        }
      }
    },
    adAccountsOptions (): Array<{ label: string, value: string }> {
      return this.adAccounts?.map(_ => ({ label: _.name, value: _.id })) || []
    },
    facebookPage (): FacebookPage | undefined {
      return this.facebookPages?.find(_ => +_?.id === this.page_id)
    },
    adAccount (): { label: string, value: string | null } {
      const adAccount = (isFinite(this.ad_account_id) && this.adAccounts)
        ? this.adAccounts.find(_ => +_.id === this.ad_account_id)
        : undefined
      return adAccount
        ? { label: adAccount.name, value: adAccount.id }
        : { label: 'Loading...', value: null }
    }
  },
  methods: {
    selectFacebookPage ({ id }: { id: string }): void {
      this.page_id = +id
    },
    selectAdAccount ({ value }: { value: string }): void {
      this.ad_account_id = +value
      if (isFinite(this.ad_account_id)) {
        this.facebookPages = null
        this.page_id = NaN
        this.getPages(this.ad_account_id)
      }
    },
    async getAdAccounts (): Promise<AxiosResponse> {
      const axiosResponse: AxiosResponse<TJsonApiBody> = await IntegrationService.get_ad_accounts(this.service)
      this.adAccounts = jsona.deserialize(axiosResponse.data)
      if (!isFinite(this.ad_account_id)) {
        this.selectAdAccount({ value: this.adAccounts[0].id })
      }
      return axiosResponse
    },
    async getPages (adAccountId: number): Promise<AxiosResponse> {
      const axiosResponse: AxiosResponse<TJsonApiBody> = await IntegrationService.getServicePages(
        this.service,
        `?filter[0][field]=ad_account_id&filter[0][value]=${adAccountId}&filter[1][field]=is_enabled&filter[1][value]=1`
      )
      this.facebookPages = jsona.deserialize(axiosResponse.data)
      if (!isFinite(this.page_id) && this.facebookPages?.length) {
        this.selectFacebookPage(this.facebookPages[0])
      }
      return axiosResponse
    },
    saveRequestBodyData (): unknown {
      const result: Partial<AutoPostBoostingRuleData> = {}

      if (this.name !== this.serverData.name) {
        result.name = this.name
      }
      if (this.boosted_posts_count !== this.serverData.boosted_posts_count) {
        result.boosted_posts_count = this.boosted_posts_count
      }

      return result
    },
    async save (): Promise<AxiosResponse<unknown> | null> {
      return PostBoostingService.updatePostBoostingRule(this.service, this.saveRequestBodyData(), this.ruleId)
    },
    createRequestBodyData (): unknown {
      return {
        name: this.name,
        boosted_posts_count: this.boosted_posts_count,
        page_id: this.page_id,
        ad_account_id: this.ad_account_id
      }
    },
    async create (): Promise<AxiosResponse<unknown> | null> {
      return PostBoostingService.createPostBoostingRule(this.service, this.createRequestBodyData())
    },
    frontEndErrorsHandler (): boolean {
      return false
    }
  },
  created () {
    if (this.ruleId) {
      this.$emit('reload-rule', (response) => {
        this.getAdAccounts()
        if (response?.data?.data?.attributes?.ad_account_id) {
          this.getPages(response.data.data.attributes.ad_account_id)
        }
      })
    } else {
      this.getAdAccounts()
    }
  }
})

